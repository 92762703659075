import opentype from 'opentype.js';
import { Font, OpenTypeFont } from '../global-types';

export const getFontId = (fontFamily: string): number => {
  if (fontFamily) {
    const id = fontFamily.split('-')[1];
    return id ? +id : 0;
  }
  return 0;
};

/**
 * Get the font family name given the font id
 *
 * @param id Font id
 * @returns Font family name
 */
export const getFontName = (id: number): string => `fontid-${id}`;

export const addFontFace = async (id: number, url: string) => {
  const loadedFace = await new FontFace(getFontName(id), `url(${url})`).load();
  document.fonts.add(loadedFace);
};

export const addMultipleFontFaces = (fonts: Font[]) => {
  const addedFonts = fonts.map((font) => addFontFace(font.id, font.url));
  return Promise.all(addedFonts);
};

const parseFontsWithOpenType = (font: Font, openTypeFont: opentype.Font) => {
  const fontKey = getFontName(font.id);
  return {
    [fontKey]: {
      font: openTypeFont,
      name: font.name,
    },
  };
};

export const loadOpenTypeFonts = (fonts: Font[]): Promise<OpenTypeFont[]> => {
  const openFonts = fonts.map((font) => {
    return new Promise((resolve, rejects) => {
      opentype.load(font.url, (err, openFont) => {
        if (err) {
          rejects(err);
        } else if (openFont) {
          resolve(parseFontsWithOpenType(font, openFont));
        }
      });
    });
  });
  return Promise.all(openFonts) as Promise<OpenTypeFont[]>;
};

const PT_TO_PX_FACTOR = 4 / 3;

export const FONT_SCALE_FACTOR = PT_TO_PX_FACTOR * 4;

/**
 * Converts a given font size in points unit to the corresponding size in pixels, taking scaling factor into consideration.
 *
 * @param size Font size in Points
 * @returns Font size in Pixels
 */
export const convertPointToPixel = (size: number): number => Math.round(size * FONT_SCALE_FACTOR);

/**
 * Converts a given font size in pixels unit to the corresponding size in points, taking scaling factor into consideration.
 *
 * @param size Font size in Pixels
 * @returns Font size in Points
 */
export const convertPixelToPoint = (size: number): number => Math.round(size / FONT_SCALE_FACTOR);
