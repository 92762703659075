import API from '@ce-digital/api-middleware';
import { ApiResponse } from '../global-types';
import { StickerData } from '../global-types';
import { processResponse } from './middleware';

const PODService = new API();

/**
 * Get call to get stickers data
 * @param page - pagination parameter (25 stickers per request)
 * @param keyword - search parameter (search by word)
 * @param signal - optional AbortSignal for cancelling the request
 * @returns array of stickers data
 */

export const getStickers = async (
  page: number,
  keyword: string,
  signal?: AbortSignal,
): Promise<ApiResponse<StickerData[] | null>> => {
  const encodedKeyword = encodeURIComponent(keyword);
  const response = PODService.get(
    '/customization',
    `/stickers?page=${page}&keyword=${encodedKeyword}&partial_search=true`,
    {
      signal,
    },
  );

  return new Promise((resolve, reject) => {
    processResponse(response, resolve, reject);
  });
};
