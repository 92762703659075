import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/card-context';
import { CanvasDataTypes } from '../../../utils';

export const useCardZoneValidation = () => {
  const { t } = useTranslation();
  const {
    cardState: { cardFacesList },
  } = useCardContext();

  const checkMandatoryTextFields = (face) => {
    if (!face.texts || face.texts.length === 0) {
      return true;
    }

    const textObjects = face.canvas.current._objects.filter(
      (obj) =>
        obj.type === 'textbox' &&
        (obj.data?.type === 'text' || obj.data?.customType === 'text' || obj.data?.type === 'editable-text'),
    );

    if (textObjects.length === 0 && face.texts.length > 0) {
      return false;
    }

    return textObjects.every(
      (obj) => obj.isModified === true || (obj.text && obj.text.trim().length > 0 && obj.originalText !== obj.text),
    );
  };

  const checkEditableTextAreas = (face) => {
    const editableTextObjects = face.canvas.current._objects.filter(
      (obj) => obj.data?.type === 'editable-text' || obj.data?.customType === 'editable-text',
    );

    if (editableTextObjects.length === 0) {
      return true;
    }

    return editableTextObjects.every(
      (obj) => obj.isModified === true || (obj.text && obj.text.trim().length > 0) || obj.originalText !== obj.text,
    );
  };

  const checkMandatoryPhotoZones = (face) => {
    if (!face.zones || face.zones.length === 0) {
      return true;
    }

    const photoZones = face.zones.filter((zone: any) => zone.type === 'photo' || zone.customType === 'photo');

    if (photoZones.length === 0) {
      if (face.photoZoneTemplate) {
        const photoObjects = face.canvas.current._objects.filter(
          (obj) =>
            (obj.name && obj.name.startsWith('photozone-')) ||
            obj.data?.type === 'photo' ||
            obj.data?.customType === 'photo' ||
            obj.data?.type === 'user-image' ||
            obj.data?.customType === 'user-image',
        );

        if (photoObjects.length === 0) {
          return false;
        }

        const hasImage = photoObjects.some(
          (obj) =>
            obj.data?.type === 'user-image' || obj.data?.customType === 'user-image' || obj._element !== undefined,
        );

        return hasImage;
      }

      return true;
    }

    const photoObjects = face.canvas.current._objects.filter(
      (obj) =>
        (obj.name && obj.name.startsWith('photozone-')) ||
        obj.data?.type === 'photo' ||
        obj.data?.customType === 'photo' ||
        obj.data?.type === 'user-image' ||
        obj.data?.customType === 'user-image',
    );

    if (photoObjects.length === 0 && photoZones.length > 0) {
      return false;
    }

    const photoZoneIds = photoZones.map((zone: any) => zone.id || zone.ID);

    const allZonesFilled = photoZoneIds.every((zoneId) => {
      const hasPhotoInZone = photoObjects.some((obj) => {
        const isMatchingZone =
          obj.name === `photozone-${zoneId}` || obj.data?.photoZoneId === zoneId || obj.data?.zoneId === zoneId;

        const hasImage =
          obj.data?.type === 'user-image' || obj.data?.customType === 'user-image' || obj._element !== undefined;

        return isMatchingZone && hasImage;
      });

      return hasPhotoInZone;
    });

    return allZonesFilled;
  };

  const checkEditableAreas = (face) => {
    if (!face.editableAreas || face.editableAreas.length === 0) {
      return true;
    }

    const editableAreaObjects = face.canvas.current._objects.filter(
      (obj) =>
        obj.name?.startsWith('area-') || obj.data?.type === 'editable-area' || obj.data?.customType === 'editable-area',
    );

    if (editableAreaObjects.length === 0 && face.editableAreas.length > 0) {
      return false;
    }

    return (
      editableAreaObjects.length > 0 &&
      editableAreaObjects.every((obj) => obj.isModified === true || obj.data?.hasContent === true)
    );
  };

  const hasRelevantObjects = (face) => {
    const relevantTypes = [
      CanvasDataTypes.UserText,
      CanvasDataTypes.StickerImage,
      CanvasDataTypes.UserImage,
      CanvasDataTypes.Placeholder,
    ];

    return face.canvas.current._objects.some((obj) => relevantTypes.includes(obj?.data?.type));
  };

  const hasUneditedPlaceholderText = (face) => {
    if (!face || !face.canvas || !face.canvas.current || !face.canvas.current._objects) {
      return false;
    }

    const placeholderObjects = face.canvas.current._objects.filter(
      (obj) =>
        obj.type === 'textbox' &&
        (obj.data?.type === 'placeholder' || obj.data?.customType === 'placeholder') &&
        (obj.isModified === false || obj.isModified === undefined) &&
        obj.originalText === obj.text,
    );

    return placeholderObjects.length > 0;
  };

  const isFaceEdited = (face) => {
    if (!face || !face.canvas || !face.canvas.current || !face.canvas.current._objects) {
      return false;
    }

    const hasNoMandatoryElements =
      (!face.texts || face.texts.length === 0) && (!face.zones || face.zones.length === 0) && !face.photoZoneTemplate;

    if (hasNoMandatoryElements) {
      return true;
    }

    if (face.canvas.current._objects.length <= 1) {
      return false;
    }

    const textFieldsEdited = checkMandatoryTextFields(face);
    const photoZonesFilled = checkMandatoryPhotoZones(face);
    const editableAreasEdited = checkEditableAreas(face);
    const editableTextEdited = checkEditableTextAreas(face);
    const noPlaceholderText = !hasUneditedPlaceholderText(face);

    if (!photoZonesFilled && face.zones && face.zones.length > 0) {
      return false;
    }

    const hasUserAddedContent = face.canvas.current._objects.some(
      (obj) =>
        obj.data?.type === 'photo' ||
        obj.data?.customType === 'photo' ||
        obj.data?.type === 'user-image' ||
        obj.data?.customType === 'user-image' ||
        obj.data?.type === 'sticker-image' ||
        obj.data?.customType === 'sticker-image' ||
        obj.isModified === true ||
        (obj.type === 'textbox' && obj.originalText !== obj.text),
    );

    if (hasUserAddedContent) {
      if (
        !photoZonesFilled &&
        ((face.zones && face.zones.some((zone: any) => zone.type === 'photo' || zone.customType === 'photo')) ||
          face.photoZoneTemplate)
      ) {
        return false;
      }

      return true;
    }

    return textFieldsEdited && photoZonesFilled && editableAreasEdited && editableTextEdited && noPlaceholderText;
  };

  const validateZones = useCallback(
    (userAttemptedProceed) => {
      const frontFace = cardFacesList.find((face) => face.type === 'front');

      const insideFace = cardFacesList.find((face) => face.type === 'inside');
      const backFace = cardFacesList.find((face) => face.type === 'back');

      if (!frontFace) {
        return {
          canProceed: true,
          showPopup: false,
          showToast: false,
          toastMessage: { title: '', description: '' },
          popupMessage: { title: '', description: '' },
        };
      }

      const frontTextEdited = frontFace.canvas?.current?._objects.some(
        (obj: any) => obj.type === 'textbox' && obj.originalText !== obj.text,
      );

      const frontPhotoZonesFilled = frontFace.canvas?.current ? checkMandatoryPhotoZones(frontFace) : true;

      const hasFrontPhotoZones =
        (frontFace.zones &&
          frontFace.zones.some((zone: any) => zone.type === 'photo' || zone.customType === 'photo')) ||
        frontFace.photoZoneTemplate;

      let isFrontEdited = frontFace.canvas?.current ? isFaceEdited(frontFace) : true;

      if (frontTextEdited) {
        isFrontEdited = true;
      }

      if (hasFrontPhotoZones && !frontPhotoZonesFilled) {
        isFrontEdited = false;
      }

      const isInsideEdited = insideFace?.canvas?.current ? isFaceEdited(insideFace) : true;
      const isBackEdited = backFace?.canvas?.current ? isFaceEdited(backFace) : true;

      const hasInsideObjects = insideFace?.canvas?.current ? hasRelevantObjects(insideFace) : false;
      const hasBackObjects = backFace?.canvas?.current ? hasRelevantObjects(backFace) : false;

      if (!isFrontEdited && !(isInsideEdited || isBackEdited)) {
        return {
          canProceed: false,
          showPopup: userAttemptedProceed,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (!isFrontEdited && (isInsideEdited || isBackEdited)) {
        return {
          canProceed: false,
          showPopup: userAttemptedProceed,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && (!isInsideEdited || !hasInsideObjects) && (!isBackEdited || !hasBackObjects)) {
        return {
          canProceed: userAttemptedProceed,
          showPopup: !userAttemptedProceed,
          showToast: false,
          toastMessage: { title: '', description: '' },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && (isInsideEdited || isBackEdited)) {
        return {
          canProceed: true,
          showPopup: true,
          showToast: false,
          toastMessage: { title: '', description: '' },
          popupMessage: {
            title: t('zoneValidation.ready.title'),
            description: t('zoneValidation.ready.description'),
          },
        };
      } else {
        return {
          canProceed: false,
          showPopup: false,
          toastMessage: {
            title: t('zoneValidation.error.title'),
            description: t('zoneValidation.error.description'),
          },
          popupMessage: { title: '', description: '' },
        };
      }
    },
    [cardFacesList],
  );

  return { validateZones };
};

export default useCardZoneValidation;
