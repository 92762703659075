import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAppContext } from '../../../context/app-context';
import {
  hideLoadingScreen,
  setIsToasterOpen,
  showLoadingScreen,
} from '../../../context/app-context/app-context-actions';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { ApiResponse } from '../../../global-types';
import { CleanUpImageResponse, ImageResponse } from '../../../global-types/images';
import { useActiveCanvas, useFeatureFlags, useIsPodProductCode } from '../../../hooks';
import { config } from '../../../regional-config';
import { cleanUpImage, linkPhoto, uploadPhoto, uploadPhotoS3 } from '../../../services/image';
import { PodHandwritingAdded } from '../../../utils/analytics/analytics-types';
import { pushPodHandwritingAdded } from '../../../utils/analytics/analytics-utils';
import { imageConverter } from '../../../utils/utility/image-converter';
import { createSingleAssetUrl } from '../../../utils/utility/image-create-single-asset-url';
import { validateUploadedImageFormat } from '../../../utils/utility/validate-uploaded-image-format';
import { addWamImageToCanvas } from '../../card-editor/utils';
import { InstructionStep } from './fragments/instruction-step';
import { StepDataProp, WamInstructionsDrawerProps } from './wam-instructions-drawer-types';
import styles from './wam-instructions-drawer.module.scss';

export const WamInstructionsDrawer = ({
  onClose,
  isOpen,
  onImageSelect,
  onCrop,
}: WamInstructionsDrawerProps): React.ReactElement => {
  const { t } = useTranslation();
  const { cardState } = useCardContext();

  const WAM_UPLOAD_STEPS = [
    {
      title: `${t('writeMessage.stepOneTitle')}`,
      text: `${t('writeMessage.stepOneText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/600q37bg0hqGvDCJ4Sp3B/ea827a0d85394b05fdc36c65d0e7262e/MGE-sign-and-send-step-01.jpg',
    },
    {
      title: `${t('writeMessage.stepTwoTitle')}`,
      text: `${t('writeMessage.stepTwoText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/7k3pehUQwZpz7PTiQ2Cwj7/2f982b3e5f8d2e25e1bdf5a7cdffa3f4/MGE-sign-and-send-step-02.jpg',
    },
    {
      title: `${t('writeMessage.stepThreeTitle')}`,
      text: `${t('writeMessage.stepThreeText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/5se61qUw51OVhvzSN9weXg/cd38161c01ae0960887da595e402eb22/MGE-sign-and-send-step-03.jpg',
    },
  ];
  const steps: StepDataProp[] = WAM_UPLOAD_STEPS;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const canvas = useActiveCanvas();
  const { appDispatch } = useAppContext();

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isPodProductCode = useIsPodProductCode();
  const { S3_PHOTO_UPLOAD } = useFeatureFlags();
  const errorMessage = t('writeMessage.sizeErrorMessage');

  const uploadImage = async (imageToUpload: File) => {
    const errorMessage = t('writeMessage.uploadErrorMessage');

    const formData = new FormData();
    formData.append('file', imageToUpload);
    formData.append('is_handwriting_image', 'true');
    formData.append('display_indicator', `${isPodProductCode}`);

    try {
      if (!initializedData?.project_id) throw new Error('No project id');

      const onFormatValidationError = () => {
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.onlyImagesMessage'),
        });
      };

      await validateUploadedImageFormat(imageToUpload, onFormatValidationError, (exifStrippedImage) => {
        formData.set('file', exifStrippedImage);
      });

      showLoadingScreen(appDispatch, 'Uploading your image...');

      // Generate S3 asset URL
      const s3Url = S3_PHOTO_UPLOAD ? await createSingleAssetUrl(imageToUpload) : '';

      const uploadResp = S3_PHOTO_UPLOAD
        ? await (uploadPhotoS3(formData, false, s3Url) as Promise<ApiResponse<ImageResponse>>)
        : await (uploadPhoto(formData) as Promise<ApiResponse<ImageResponse>>);

      if (uploadResp && uploadResp.meta.code !== 201) {
        throw new Error();
      }

      let imageId = '';
      const imgRefId = S3_PHOTO_UPLOAD
        ? uploadResp.data?.[0].photo_tray_image_id
        : uploadResp.data?.photo_tray_image_id;

      const linkPhotoResp = await linkPhoto({ image_reference_id: imgRefId }, initializedData?.project_id as string);
      if (linkPhotoResp && linkPhotoResp.meta.code !== 201) {
        throw new Error();
      }
      imageId = linkPhotoResp.data?.image_id || '';

      if (!imageId) {
        throw new Error();
      }

      showLoadingScreen(appDispatch, 'Making it look handwritten...');
      const cleanUpResp = await cleanUpImage(initializedData?.project_id as string, imageId, {});
      if (cleanUpResp && cleanUpResp.meta.code !== 201) {
        throw new Error();
      }

      return {
        imageId: imageId,
        imageUrl: (cleanUpResp.data as CleanUpImageResponse).image_url,
        cleanUpVersionId: (cleanUpResp.data as CleanUpImageResponse).version_id,
      };
    } catch (e) {
      setIsToasterOpen(appDispatch, {
        title: t('writeMessage.errorTitle'),
        children: errorMessage,
        variant: ToastVariants.Error,
      });
      hideLoadingScreen(appDispatch);
      return {};
    }
  };

  const handleImageUpload = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const uploadedFiles = (event.target as HTMLInputElement).files;

    if (uploadedFiles) {
      const convertedFile = await imageConverter(uploadedFiles[0])();
      const isInvalidSize = convertedFile.size >= config.imagesUpload.maxFileSize;
      if (isInvalidSize) {
        onClose();
        return setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: `${t('writeMessage.sizeTitleError')}`,
          children: errorMessage,
        });
      }

      const { imageUrl, imageId, cleanUpVersionId } = await uploadImage(convertedFile);
      if (!imageUrl) {
        return;
      }
      await addWamImageToCanvas(
        imageUrl,
        isPodProductCode,
        cardState,
        canvas,
        imageId,
        cleanUpVersionId,
        onImageSelect,
        onCrop,
      );

      const handwritingAddedEventData: Omit<PodHandwritingAdded, 'event_id'> = {
        event: 'pod_handwriting_added',
        page_number: cardState?.activeCardIndex === 0 ? '1' : '2',
      };

      pushPodHandwritingAdded(handwritingAddedEventData);

      hideLoadingScreen(appDispatch);
    }
  };

  useEffect(() => {
    buttonRef.current?.focus();
  }, [isOpen]);

  const FooterContent = (): React.ReactElement => (
    <>
      <Button
        id="wam-upload-button"
        click={() => {
          inputRef.current?.click();
        }}
        addClass={styles['upload-button']}
        ref={buttonRef}
        mode={config?.wamDrawer?.buttonModes || ButtonModes.Primary}
        startIcon={{ name: CEIconNames.ImageUpload, size: 24 }}
        typographyVariant={config?.wamDrawer?.buttonTypographyVariant || TypographyVariants.BodyBold}
      >
        {t('writeMessage.footerContent')}
      </Button>
      <input
        type="file"
        aria-labelledby="wam-upload-button"
        data-testid="wam-upload-input"
        ref={inputRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/jpeg,image/jpg,image/gif,image/png,image/heic,image/heif,image/webp "
      />
    </>
  );

  return (
    <Drawer
      id="wam-instructions-drawer"
      drawerPosition={DrawerPositions.Left}
      onClose={onClose}
      isOpen={isOpen}
      closeIconSize={24}
      addClass={styles['wam-instructions-drawer']}
      footerContent={<FooterContent />}
      trapFocus
      headerContent={
        config?.wamDrawer?.hasTitle ? (
          <Typography variant={TypographyVariants.Headline3}>{t('wamDrawer.title')}</Typography>
        ) : null
      }
      closeIcon={CEIconNames.XClose}
    >
      {steps.map(({ imageUrl, title, text }, index) => (
        <InstructionStep
          key={`wam-instruction-${index + 1}`}
          imageUrl={imageUrl}
          text={text}
          title={title}
          stepNumber={index + 1}
        />
      ))}
    </Drawer>
  );
};
