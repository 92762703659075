type AdjustScaleParams = {
  canvasWidth: number;
  canvasHeight: number;
  deviceType: 'desktop' | 'tablet' | 'mobile';
};

export const calculateAdjustedScale = ({ canvasWidth, canvasHeight, deviceType }: AdjustScaleParams): number => {
  let containerWidth;
  let containerHeight;

  if (window.innerWidth >= 1920) {
    // For 1920px devices
    containerWidth = 1240;
    // containerHeight = 806;
  } else if (window.innerWidth >= 1440) {
    // For 1440px devices
    containerWidth = 886;
    // containerHeight = 624;
  } else if (window.innerWidth >= 1336) {
    // For 1336px devices
    containerWidth = 860;
    // containerHeight = 494;
  } else if (window.innerWidth >= 1180) {
    // For 1180px devices
    containerWidth = 660;
    // containerHeight = 546;
  } else {
    // For devices below 1180px
    containerWidth = window.innerWidth - 32;
  }

  if (deviceType === 'desktop') {
    containerHeight = window.innerHeight - 210 - 64;
  } else if (deviceType === 'tablet') {
    containerHeight = window.innerHeight - 335 - 36 - 20;
  } else if (deviceType === 'mobile') {
    const aspectRatio = canvasWidth / canvasHeight;
    const isNearlySquare = aspectRatio >= 0.9 && aspectRatio <= 1.1;

    const bottomPadding = isNearlySquare ? 40 : 80;
    containerHeight = window.innerHeight - 235 - 84 - 36 - bottomPadding;
  }

  const scale = Math.min(containerWidth / canvasWidth, containerHeight / canvasHeight);
  return scale;
};
