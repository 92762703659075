import { isMobile } from 'react-device-detect';
import { fabric } from 'fabric';
import { handleZoomIn, resetZoom } from '../../components/card-face/utils/zoom-mobile-text';
import { FabricTextBox } from '../../global-types';
import { CanvasDataTypes, getGroupedItemByName } from '../canvas';

const enforceMaxLength = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
  }

  return text;
};

/**
 * Creates a temporary input and then adds text to active photoTextZone on deselect.
 *
 * @param currentCanvas - Current fabric canvas.
 * @param appDispatch - Dispatch from useAppContext
 * @param cardState - Context state from useCardContext
 * @param cardDispatch - Dispatch from useCardContext
 * @example
 *
 *   handleTemporaryTextEditor(canvas.current, appState, cardState, cardDispatch);
 */
export const handleTemporaryTextEditor = (currentCanvas: fabric.Canvas) => {
  // Get Current Zone, text item
  const photoTextZone = currentCanvas.getActiveObject() as fabric.Group & FabricTextBox;

  const originalText = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone) as fabric.Textbox;

  if (currentCanvas && photoTextZone && originalText) {
    const { top, left, width, height } = photoTextZone;

    // Initialize temporary input over TAM photoZone.  Populate with original text.
    const temporaryInput = new fabric.Textbox(
      originalText.text as string,
      {
        textAlign: originalText.textAlign,
        fontSize: originalText.fontSize,
        fontWeight: 'normal',
        top: Math.ceil((top as number) - (height as number) / 2),
        isWrapping: true,
        left: Math.ceil((left as number) - (width as number) / 2),
        fontFamily: originalText.fontFamily,
        width,
        dirty: true,
        deltaY: 0,
        height,
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        clipPath: originalText.clipPath,
        data: {
          testId: 'temporary-text',
          parentPhotozoneName: photoTextZone.name,
          ID: photoTextZone.ID,
        },
        name: CanvasDataTypes.TemporaryTAMInput, //name can be used to prevent opening text drawer
        CanResizeTextArea: photoTextZone.CanResizeTextArea,
        CanEditFontSize: photoTextZone.CanEditFontSize,
        FontAutoSize: photoTextZone.FontAutoSize,
        CanEditFontColor: photoTextZone.CanEditFontColor,
      } as FabricTextBox,
    );

    // Hide original textbox and add Temporary text box to canvas.  Then, select the input and enter edit mode.
    originalText.set({ visible: false });
    currentCanvas.add(temporaryInput);

    // TODO: Implement TAM zoom on mobile devices
    //further exploration on zoom implementation is needed
    /*if(isMobileApp) {
      const {x, y} = temporaryInput.getCenterPoint();
      const zoomToX = x / 2, zoomToY = y / 2;
      //params: (x,y) coordinates to zoomIn, zoom level
      currentCanvas.zoomToPoint({x: zoomToX, y: zoomToY}, 0.8);
    }*/

    currentCanvas.setActiveObject(temporaryInput);
    //enter editing on temporary input and set text selection
    temporaryInput.enterEditing();
    temporaryInput.selectAll();

    /**
     * Event handler for zooming on mobile devices
     */
    if (isMobile) {
      const objActiveCanvas = currentCanvas.getActiveObject();
      if (!objActiveCanvas) return;

      handleZoomIn(currentCanvas, objActiveCanvas);
    }

    const photoTextZoneButton = getGroupedItemByName(CanvasDataTypes.PhotoTextZoneButton, photoTextZone);
    if (photoTextZoneButton) {
      photoTextZoneButton.set({ visible: false });
    }

    //handle needed actions after user exits editing the message
    temporaryInput.on('editing:exited', () => {
      if (isMobile) resetZoom(currentCanvas);
      //update the original photoTextZone's textbox with the newly added text and make it visible
      if (temporaryInput.text === '') {
        originalText.set({ text: temporaryInput.text });
        photoTextZone.data.hasContent = false;
        photoTextZoneButton?.set({
          visible: true,
        });
      } else if (temporaryInput.text) {
        originalText.set({
          textAlign: temporaryInput.textAlign,
          fontSize: temporaryInput.fontSize,
          fontFamily: temporaryInput.fontFamily,
          text: enforceMaxLength(temporaryInput.text, 3000),
          visible: true,
          height: temporaryInput.height,
          width: temporaryInput.width,
        });

        photoTextZone.data.hasContent = true;
        originalText.fire('changed');
      }

      //remove the temporary textbox
      currentCanvas.remove(temporaryInput);
      //bring back focus to photoTextZone
      currentCanvas.setActiveObject(photoTextZone);
      currentCanvas.renderAll();
    });
  }
};
