import { isAndroid } from 'react-device-detect';

const DOUBLE_CLICK_DELAY = 300;

interface ClickState {
  lastClickTime: number;
  clickCount: number;
}

const clickStates = new Map<string, ClickState>();

export const trackTextboxClicks = (textbox: fabric.Textbox, callback: (isDoubleClick: boolean) => void) => {
  if (!isAndroid) {
    callback(false);
    return;
  }

  const textboxId = textbox.name || textbox.text || `textbox-${textbox.left}-${textbox.top}`;
  const currentTime = Date.now();
  const clickState = clickStates.get(textboxId) || { lastClickTime: 0, clickCount: 0 };

  if (currentTime - clickState.lastClickTime < DOUBLE_CLICK_DELAY) {
    clickState.clickCount++;

    if (clickState.clickCount === 2) {
      callback(true);
      clickState.clickCount = 0;
    }
  } else {
    clickState.clickCount = 1;

    setTimeout(() => {
      if (clickStates.get(textboxId)?.clickCount === 1) {
        callback(false);
      }
    }, DOUBLE_CLICK_DELAY);
  }

  clickState.lastClickTime = currentTime;
  clickStates.set(textboxId, clickState);
};
