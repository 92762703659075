import React, { ReactElement, lazy, Suspense, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { AlignmentDrawer } from '@hallmark/web.page-components.print-on-demand.text-editor.alignment-drawer';
import { SizeDrawer } from '@hallmark/web.page-components.print-on-demand.text-editor.size-drawer';
import {
  TextOptionDrawer,
  TextOptionDrawerVariant,
} from '@hallmark/web.page-components.print-on-demand.text-editor.text-option-drawer';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import {
  setIsTextDrawerOpen,
  setAllDrawersClosed,
  setIsAlignmentDrawerOpen,
  setIsFontDrawerOpen,
  setIsDeleteConfirmationDialogOpen,
  setIsDeleteConfirmationDialogClosed,
  setIsClearConfirmationDialogClosed,
  setIsResetConfirmationDialogClosed,
  setIsOrderDrawerOpen,
  setIsSizeDrawerOpen,
  setIsColorDrawerOpen,
  useAppContext,
  setIsToasterOpen,
  setIsClearConfirmationDialogOpen,
  setIsImageEditDrawerOpen,
  setIsScaleDrawerOpen,
} from '../../../context/app-context';
import { ImageEditDrawerModes } from '../../../context/app-context/app-context-types';
import { useCardContext } from '../../../context/card-context';
import { useCropContext } from '../../../context/crop-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { ApiResponse, CardType, CustomFabricObject, FabricObject } from '../../../global-types';
import { DialogType } from '../../../global-types/dialog';
import { useActiveCanvas, useFeatureFlags, useIsPodProductCode } from '../../../hooks';
import { useKeyboardDelete } from '../../../hooks/useKeyboardDelete';
import { config } from '../../../regional-config';
import { deleteImage } from '../../../services';
import {
  getGroupedTextObject,
  getObjectByName,
  CanvasDataTypes,
  getObjectsByType,
  clearPhotoTextZone,
  getGroupedItemByName,
  convertPointToPixel,
  convertPixelToPoint,
} from '../../../utils';
import { getTextAndImageObjects, moveObjectWithinFiltered } from '../../../utils/canvas';
import { FontDrawer as DynamicTextBoxDrawer } from '../../card-controls/font-drawer';
import { addPhotozoneBackground } from '../../card-editor/card-editor-utils';
import { addEditableAreas } from '../../card-editor/utils';
import { ImageEditDrawer } from '../image-edit-drawer/image-edit-drawer';
import { OrderDrawer } from '../order-drawer';
import { RotateDrawer } from '../rotate-drawer';
import { ScaleDrawer } from '../scale-drawer';
import { WamInstructionsDrawer } from '../wam-instructions-drawer';
import styles from './drawer-container.module.scss';
import { FontDrawer } from './fragments';
import { TextColorDrawer } from './fragments/text-color-drawer';
import { useImageUploadHandlers } from './hooks/useImageUploadHandlers';

const DeleteConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.DeleteConfirmationDialog,
  })),
);

const ResetConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ResetConfirmationDialog,
  })),
);

const ClearConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ClearConfirmationDialog,
  })),
);

const ImageUploadDrawerComponent = lazy(() =>
  import('../../card-controls/image-upload-drawer').then((module) => ({
    default: module.ImageUploadDrawer,
  })),
);

const StickerPickDrawerComponent = lazy(() =>
  import('../../card-controls/sticker-pick-drawer').then((module) => ({
    default: module.StickerPickDrawer,
  })),
);

export const DrawerContainer = (): ReactElement => {
  const canvas = useActiveCanvas();
  const { appState, appDispatch } = useAppContext();
  const openImageEditDrawer = useCallback(
    () => setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.UserImage),
    [appDispatch],
  );
  const { startCropping, isCropping, finishCropping, cancelCropping } = useCropContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const isPodProductCode = useIsPodProductCode();
  // We add this validation in case the project type is a digital card but since we want
  // to handle the DG card as a P card we valide the product code is from a P card so we show the same drawers for both
  const cardType = isPodProductCode ? 'P' : 'S';

  const { onImageUpload } = useImageUploadHandlers();

  const {
    isImageUploadDrawerOpen,
    isTextDrawerOpen,
    isAlignmentDrawerOpen,
    isWamDrawerOpen,
    isRotationDrawerOpen,
    isSizeDrawerOpen,
    isImageEditDrawerOpen,
    imageEditDrawerMode,
    isFontDrawerOpen,
    isColorDrawerOpen,
    isOrderDrawerOpen,
    isScaleDrawerOpen,
    isImageUploadToPhotoZoneOpen,
    isStickerPickDrawerOpen,
    dialogType,
  } = appState;

  const { t } = useTranslation();
  const { DYNAMIC_TEXBOX_DRAWER } = useFeatureFlags();

  const resetErrorMessage = t('drawerContainer.resetErrorMessage');
  const imageErrorTitle = t('drawerContainer.imageErrorTitle');

  const closeAllDrawers = useCallback(() => setAllDrawersClosed(appDispatch), [appDispatch]);

  const deleteSelectedObject = (obj: FabricObject) => {
    if (obj) {
      canvas?.current?.remove(obj);
      canvas?.current?.renderAll();
    }

    return obj;
  };

  const handleClearConfirmation = () => {
    if (canvas?.current) {
      clearPhotoTextZone(canvas.current);
      setAllDrawersClosed(appDispatch);
      closeClearConfirmationDialog();
    }
  };

  const handleDeleteConfirmation = async () => {
    const activeObject = canvas?.current?.getActiveObject() as FabricObject;

    if (activeObject?.type === 'image' && activeObject?.data?.type !== CanvasDataTypes.StickerImage) {
      try {
        await deleteImage(initializedData?.project_id as string, activeObject?.name as string);

        deleteSelectedObject(activeObject);
      } catch (error) {
        setIsToasterOpen(appDispatch, {
          title: 'Deletion Failed',
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
      }

      if (activeObject?.data?.type === CanvasDataTypes.PhotoZoneImage && canvas?.current) {
        addPhotozoneBackground(activeObject, canvas?.current, cardState);
      }
    } else {
      deleteSelectedObject(activeObject);
    }

    const image = deleteSelectedObject(activeObject);

    const isPhotoImage = image?.data?.type === CanvasDataTypes.PhotoZoneImage;
    if (isPhotoImage && canvas?.current) {
      const currentZoneId = image?.data?.photoZoneId;
      if (!currentZoneId) {
        return;
      }
      //restore the photo zones
      const photoZones = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZone);
      const currentPhotoZone = photoZones.find((zone) => zone.data?.id === currentZoneId);
      if (currentPhotoZone) {
        currentPhotoZone.set({ visible: true });
        currentPhotoZone.data.hasContent = false;
      }
      // restore the photo zone button
      const photoButton = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZoneButton).find(
        (button) => button.data?.photoZoneId === currentZoneId,
      );
      photoButton?.set({ visible: true });
      canvas.current.renderAll();
    }
    // Restore editable area buttons if all objects are removed from editable area
    if (canvas?.current) {
      const objects = canvas.current.getObjects();
      if (isUserZoneEmpty(objects)) {
        // User zone is empty, so populate user zone buttons
        const cardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];
        const { editableAreas } = cardFace;
        if (editableAreas) {
          addEditableAreas(cardFace, cardState, true);
          const editableAreaObject = objects.find((el) => el?.data?.type === 'editable-area') as CustomFabricObject;
          editableAreaObject.isModified = false;
        }
      }
    }

    setAllDrawersClosed(appDispatch);
    closeDeleteConfirmationDialog();
  };

  const isUserZoneEmpty = (objects: FabricObject[]) => {
    for (const object of objects) {
      if (
        object.data?.type?.includes(CanvasDataTypes.UserText) ||
        object.data?.type?.includes(CanvasDataTypes.UserImage) ||
        object.data?.type?.includes(CanvasDataTypes.StickerImage) ||
        object.data?.type?.includes('pod-handwriting-zone')
      ) {
        return false;
      }
    }
    return true;
  };

  const handleTextEdit = () => {
    const fabricObject = canvas?.current?.getActiveObject() as fabric.Textbox;

    if (fabricObject && fabricObject.type === 'textbox') {
      fabricObject.enterEditing();

      if (/Android/i.test(navigator.userAgent)) {
        setTimeout(() => {
          if (fabricObject.hiddenTextarea) {
            fabricObject.hiddenTextarea.focus();
            fabricObject.hiddenTextarea.click();
          }
        }, 100);
      }
    }
    return true;
  };

  const handleResetConfirmation = async () => {
    const activeObject = canvas?.current?.getActiveObject() as fabric.Object;
    if (!activeObject) return;

    let response: ApiResponse<null> | null = null;

    if (activeObject?.data?.type !== CanvasDataTypes.StickerImage) {
      response = await deleteImage(initializedData?.project_id as string, activeObject?.name as string);
      if (response?.meta?.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: imageErrorTitle,
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
        closeResetConfirmationDialog();
        return;
      }
    }

    const deletedImage = deleteSelectedObject(activeObject);
    if (deletedImage && deletedImage.data?.zoneName && canvas?.current) {
      const photoTextZone = getObjectByName(deletedImage.data.zoneName, canvas.current) as fabric.Group;
      if (photoTextZone) {
        photoTextZone.data.hasContent = false;
        const zoneButton = getGroupedItemByName(CanvasDataTypes.PhotoTextZoneButton, photoTextZone);
        zoneButton?.setOptions({ visible: true });
        photoTextZone.setOptions({ selectable: true });
        canvas?.current?.renderAll();
      }
    }
    setAllDrawersClosed(appDispatch);
    closeResetConfirmationDialog();
  };

  const onSubmitOrder = () => {
    const activeObject = canvas?.current?.getActiveObject();

    if (activeObject?.data.type === ImageEditDrawerModes.UserImage) {
      setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.UserImage);
    } else if (activeObject?.data.type === ImageEditDrawerModes.StickerImage) {
      setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.StickerImage);
    } else {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const onChangeOrder = (newOrder: number) => {
    const activeObject = canvas?.current?.getActiveObject();

    if (activeObject) {
      const filteredObjects = getTextAndImageObjects(canvas?.current as fabric.Canvas);
      const maxIndex = filteredObjects.length - 1;
      const clampedOrder = Math.min(Math.max(newOrder, 0), maxIndex);

      moveObjectWithinFiltered(canvas?.current as fabric.Canvas, activeObject, clampedOrder);
    }
  };

  const openDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogOpen(appDispatch, dialogType ?? DialogType.Text);
  };

  useKeyboardDelete({ canvas: canvas?.current, openDeleteConfirmationDialog });

  const closeDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogClosed(appDispatch);
  };

  // @todo enable once TAM is added
  const openClearConfirmationDialog = () => {
    setIsClearConfirmationDialogOpen(appDispatch);
  };

  const closeClearConfirmationDialog = () => {
    setIsClearConfirmationDialogClosed(appDispatch);
    resetSelection();
  };

  const onSubmitSize = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  const onSubmitAlignment = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  const onFinishCropping = async () => {
    if (finishCropping) {
      await finishCropping();
    }
  };

  const onCloseTextOptionDrawer = () => {
    resetSelection();
    setAllDrawersClosed(appDispatch);
    canvas?.current?.discardActiveObject().renderAll();
  };

  const onCloseImageEditDrawer = () => {
    resetSelection();
  };

  /**
   *  When size drawer is closed by cancel, revert text to its original size
   *
   * @param object - object to revert back to its original size
   * @param fontSize - the text object's original size

  * @returns void
  */
  const onSizeDrawerClose = (object: fabric.Object, fontSize: number) => {
    if (!object) return;

    if (object.type === 'textbox') {
      setIsTextDrawerOpen(appDispatch);
      setIsTextDrawerOpen(appDispatch);
      object?.setOptions({ fontSize });
      object?.fire('custom:updateBorders');
      canvas?.current?.renderAll();
    } else {
      setIsScaleDrawerOpen(appDispatch);
      object?.fire('custom:updateBorders');
    }
  };

  /**
   *  When alignment drawer is closed by cancel, revert text to its original alignment
   *
   * @param textObject - object to revert back to its original alignment
   * @param textAlign - the text object's original alingment

  * @returns void
  */
  const onAlignmentDrawerClose = (textObject: fabric.Textbox, textAlign: string) => {
    setIsTextDrawerOpen(appDispatch);
    textObject.setOptions({
      textAlign,
    });
    textObject?.fire('custom:updateBorders');
    canvas?.current?.renderAll();
  };

  // @todo enable once WAM is added
  // const openResetConfirmationDialog = () => {
  //   setIsResetConfirmationDialogOpen(appDispatch);
  // };

  const closeResetConfirmationDialog = () => {
    setIsResetConfirmationDialogClosed(appDispatch);
  };

  const openColorDrawer = () => {
    setIsColorDrawerOpen(appDispatch);
  };

  const resetSelection = () => {
    setAllDrawersClosed(appDispatch);
    if (canvas?.current) {
      canvas.current.discardActiveObject();
      canvas.current.renderAll();
    }
  };

  const alignmentOptions = ['Left', 'Center', 'Right', 'Justify'];

  const mapAlignmentToIndex = (alignment) => {
    const normalizedAlignment = alignment.toLowerCase();
    switch (normalizedAlignment) {
      case 'left':
        return 0;
      case 'center':
        return 1;
      case 'right':
        return 2;
      case 'justify':
        return 3;
      default:
        return 0;
    }
  };

  const getCurrentAlignmentIndex = () => {
    const textObject = getGroupedTextObject(canvas?.current);
    if (textObject) {
      const currentAlignment = (textObject as fabric.Textbox).textAlign || 'left';
      return mapAlignmentToIndex(currentAlignment);
    }
    return 0;
  };

  const initialAlignmentValue = getCurrentAlignmentIndex();

  const buttonGroupClick = (index: number) => {
    const textObject = getGroupedTextObject(canvas?.current);
    if (textObject) {
      const alignment = alignmentOptions[index as number];
      const textAlignment = alignment.toLowerCase();

      textObject.setOptions({
        textAlign: textAlignment,
      });
      canvas?.current?.renderAll();
      textObject.fire('changed');
    }
  };

  const isAndroid = /Android/i.test(navigator.userAgent);

  const buttonActions = {
    edit: {
      handler: handleTextEdit,
      label: t('textOptionDrawer.editLabel'),
      condition: isAndroid,
    },
    fontStyle: {
      handler: () => setIsFontDrawerOpen(appDispatch),
      label: t('textOptionDrawer.fontStyleLabel'),
    },
    fontSize: {
      handler: () => setIsSizeDrawerOpen(appDispatch),
      label: t('textOptionDrawer.fontSizeLabel'),
    },
    color: {
      handler: openColorDrawer,
      label: t('textOptionDrawer.colorLabel'),
      condition: cardType === CardType.POD,
    },
    alignment: {
      handler: () => setIsAlignmentDrawerOpen(appDispatch),
      label: t('textOptionDrawer.alignmentLabel'),
    },
    order: {
      handler: () => setIsOrderDrawerOpen(appDispatch),
      label: t('textOptionDrawer.orderLabel'),
      condition: cardType === CardType.POD,
    },
    delete: {
      handler: openDeleteConfirmationDialog,
      label: t('textOptionDrawer.deleteLabel'),
      condition: cardType === CardType.POD,
    },
    clear: {
      handler: openClearConfirmationDialog,
      label: t('textOptionDrawer.clearLabel'),
      condition: cardType === CardType.SAS,
    },
  };

  useEffect(() => {
    if (isTextDrawerOpen && canvas?.current) {
      const activeTextBox = getGroupedTextObject(canvas?.current) as undefined | fabric.Textbox;
      if (activeTextBox?.isEditing) {
        activeTextBox.hiddenTextarea?.focus();
      }
    }
  }, [isTextDrawerOpen]);

  const OpenDrawer = useMemo(() => {
    if (isTextDrawerOpen) {
      return DYNAMIC_TEXBOX_DRAWER ? (
        <DynamicTextBoxDrawer />
      ) : (
        <div className={styles['drawer-container']}>
          <TextOptionDrawer
            shouldRenderFooter={config?.textOptionDrawer?.shouldRenderFooter ?? false}
            isOpen={isTextDrawerOpen}
            onClose={onCloseTextOptionDrawer}
            buttonActions={buttonActions}
            headerContent={
              config?.textOptionDrawer?.hasHeaderContent ? (
                <>
                  <Typography variant={TypographyVariants.BodyBold}>{t('textOptionDrawer.title')}</Typography>
                </>
              ) : null
            }
            renderIconVariant={config?.textOptionDrawer?.renderIconVariant ?? TextOptionDrawerVariant.Variant1}
            hasChildIcon={config?.textOptionDrawer?.hasChildIcon}
            layoutVariant={config?.textOptionDrawer?.layoutVariant}
            hideCloseButton={config?.textOptionDrawer?.hideCloseButton}
            closeIcon={CEIconNames.XClose}
            showVeil={config?.textOptionDrawer?.showVeil}
            addClass={styles['text-option-drawer-container']}
          />
        </div>
      );
    }

    if (isImageEditDrawerOpen || isCropping) {
      const isHandwriting = canvas?.current?.getActiveObject()?.data?.isPodHandwriting;
      return (
        <ImageEditDrawer
          isOpen={isImageEditDrawerOpen || isCropping}
          mode={imageEditDrawerMode}
          onClose={onCloseImageEditDrawer}
          onCrop={startCropping}
          onFinishCropping={onFinishCropping}
          onCancelCropping={cancelCropping}
          isCropping={isCropping}
          onDelete={openDeleteConfirmationDialog}
          onImageSelect={openImageEditDrawer}
          isHandwriting={isHandwriting}
        />
      );
    }

    if (isAlignmentDrawerOpen) {
      const textObject: fabric.Textbox = getGroupedTextObject(canvas?.current) as fabric.Textbox;
      const originalAligment = textObject?.textAlign || 'center';
      return (
        <div className={styles['drawer-container']}>
          <AlignmentDrawer
            isOpen={isAlignmentDrawerOpen}
            onClose={() => onAlignmentDrawerClose(textObject, originalAligment)}
            buttonGroupClick={buttonGroupClick}
            handleSubmit={onSubmitAlignment}
            btnList={config?.alignmentDrawer?.btnList ?? []}
            layoutVariant={config?.alignmentDrawer?.layoutVariant ?? undefined}
            actionButtonLabel={t('drawerContainer.actionButtonLabel') ?? ''}
            cancelButtonLabel={t('drawerContainer.cancelButtonLabel') ?? ''}
            actionButtonMode={config?.alignmentDrawer?.actionButtonMode}
            hasActionButton={config?.alignmentDrawer?.hasActionButton}
            cancelButtonMode={config?.alignmentDrawer?.cancelButtonMode}
            hasCancelButton={config?.alignmentDrawer?.hasCancelButton}
            buttonGroupMode={config?.alignmentDrawer?.buttonGroupMode}
            initialValue={initialAlignmentValue}
          />
        </div>
      );
    }

    if (isWamDrawerOpen) {
      return (
        <WamInstructionsDrawer
          isOpen={isWamDrawerOpen}
          onCrop={startCropping}
          onClose={closeAllDrawers}
          onImageSelect={openImageEditDrawer}
        />
      );
    }

    if (isSizeDrawerOpen) {
      const textObject = getGroupedTextObject(canvas?.current) as fabric.Textbox;
      const textFontSize: number = textObject?.fontSize ?? 30;
      return (
        <div className={styles['drawer-container']}>
          <SizeDrawer
            handleSubmit={onSubmitSize}
            isOpen={isSizeDrawerOpen}
            onClose={() => onSizeDrawerClose(textObject, textFontSize)}
            sizeValue={convertPixelToPoint(textFontSize).toString()}
            sizeOnChange={(valueInRange: string) => {
              if (textObject) {
                const scaledFontSize = convertPointToPixel(parseInt(valueInRange));
                textObject.setOptions({ fontSize: scaledFontSize });
                canvas?.current?.renderAll();
                textObject.fire('changed');
                textObject?.fire('custom:updateBorders');
              }
            }}
            addClass={styles['size-drawer']}
            actionButtonLabel={t('drawerContainer.actionButtonLabel') ?? ''}
            hasCancelButton={config?.sizeDrawer?.hasCancelButton}
            hasActionButton={config?.sizeDrawer?.hasActionButton}
            labelRangeLeft={t('sizeDrawer.labelRangeLeft') ?? ''}
            labelRangeRight={t('sizeDrawer.labelRangeRight') ?? ''}
            actionButtonMode={config?.sizeDrawer?.actionButtonMode}
            cancelButtonMode={config?.sizeDrawer?.cancelButtonMode}
            layoutVariant={config?.sizeDrawer?.layoutVariant}
            rangeVariant={config?.sizeDrawer?.rangeVariant}
            rangeValuePosition={config?.sizeDrawer?.rangeValuePosition}
          />
        </div>
      );
    }

    if (isFontDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <FontDrawer />
        </div>
      );
    }

    if (isScaleDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <ScaleDrawer isOpen={isScaleDrawerOpen} />
        </div>
      );
    }

    if (isRotationDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <RotateDrawer isOpen={isRotationDrawerOpen} />
        </div>
      );
    }

    if (isColorDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <TextColorDrawer />
        </div>
      );
    }

    if (isOrderDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <OrderDrawer
            isOpen={isOrderDrawerOpen}
            onClose={closeAllDrawers}
            onSubmit={onSubmitOrder}
            handleOrderChange={onChangeOrder}
          />
        </div>
      );
    }

    return <React.Fragment />;
  }, [appState, isCropping, finishCropping, startCropping]);

  return (
    <div data-testid="drawer-container">
      {OpenDrawer}
      <Suspense fallback={null}>
        <ImageUploadDrawerComponent
          isOpen={isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen}
          onUpload={onImageUpload}
          onClose={closeAllDrawers}
        />
      </Suspense>
      <Suspense fallback={null}>
        <StickerPickDrawerComponent
          isOpen={isStickerPickDrawerOpen}
          onClose={closeAllDrawers}
          onUpload={onImageUpload}
        />
      </Suspense>
      <Suspense fallback={null}>
        <DeleteConfirmationComponent
          isOpen={appState.isDeleteConfirmationDialogOpen}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={handleDeleteConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ResetConfirmationComponent
          isOpen={appState.isResetConfirmationDialogOpen}
          onClose={closeResetConfirmationDialog}
          onConfirm={handleResetConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ClearConfirmationComponent
          isOpen={appState.isClearConfirmationDialogOpen}
          onClose={closeClearConfirmationDialog}
          onConfirm={handleClearConfirmation}
        />
      </Suspense>
    </div>
  );
};
