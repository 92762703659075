import { useCallback } from 'react';
import { useCardContext } from '../context/card-context';
import { setIsPreviewDialogOpen } from '../context/card-context/card-context-actions';
import { useUserZonesValidation } from '../views/editor/hooks';
import { useHandleProjectDone } from '../views/editor/hooks/useHandleProjectDone';
import { useOrchestrateActions } from '../views/editor/hooks/useOrchestrateActions';
import { useFeatureFlags } from './useFeatureFlags';
import useIsCanvasEmpty from './useIsCanvasEmpty';
import { useIsOneToMany } from './useIsOneToMany';

export const useHandleEditingDone = () => {
  const { SKIP_PREVIEW_STEP } = useFeatureFlags();

  const { cardDispatch } = useCardContext();

  const isCanvasEmpty = useIsCanvasEmpty();
  const { skipValidation, validateUserZones, uneditedZones } = useUserZonesValidation();
  const isOneToMany = useIsOneToMany();
  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);

  const handleProjectDone = useHandleProjectDone(skipValidation, validateUserZones, uneditedZones, isOneToMany);
  const { orchestrateActions } = useOrchestrateActions();

  const handleEditingDone = useCallback(async () => {
    updateIsPreviewDialogOpen(false);
    if (SKIP_PREVIEW_STEP) {
      await orchestrateActions();
    } else {
      await handleProjectDone();
    }
  }, [isCanvasEmpty, SKIP_PREVIEW_STEP, handleProjectDone, orchestrateActions, cardDispatch]);

  return handleEditingDone;
};
