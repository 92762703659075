import { isAndroid } from 'react-device-detect';
import { fabric } from 'fabric';
import { debounce } from 'lodash';
import { CEShadeColors } from '@hallmark/web.styles.colors';
import editTextIcon from '../assets/edit-icon.svg';
import { CardContextState } from '../context/card-context';
import {
  FabricObject,
  OpenTypeFont,
  CustomFabricObject,
  CardFaceData,
  EditableTextSettings,
  FabricTextBox,
} from '../global-types';
import { FillPhotoZoneType } from '../global-types/card-face';
import { config } from '../regional-config';
import colorsList from '../styles/util.scss';
import { PodTextAdded } from './analytics/analytics-types';
import { pushPodTextAdded } from './analytics/analytics-utils';
import { trackTextboxClicks } from './click-handler';
import { findTextById } from './utility/find-text-by-id';

/**
 * This is added to custom objects in the data property
 * to make us easier identify these objects
 * @example object.data = { type: CanvasDataTypes.PhotoZone }
 */

export enum CanvasDataTypes {
  Border = 'border',
  EditableText = 'editable-text',
  EditableTextButton = 'editable-text-button',
  EditableArea = 'editable-area',
  Placeholder = 'placeholder',
  UserText = 'user-text',
  PhotoZone = 'photo-zone',
  PhotoTextZone = 'photo-text-zone',
  PhotoTextZoneLabel = 'photo-text-zone-label',
  PhotoZoneImage = 'photo-zone-image',
  PhotoTextZoneButton = 'photo-text-zone-button',
  PhotoZoneButton = 'photo-zone-button',
  PhotoZoneTextbox = 'photo-zone-textbox',
  UserImage = 'user-image',
  StickerImage = 'sticker-image',
  TemporaryTAMInput = 'temporary-tam-input',
  FoldLine = 'fold-line',
  RecipientBox = 'recipient-box',
  RecipientText = 'recipient-text',
  SenderBox = 'sender-box',
  SenderText = 'sender-text',
  UserZoneAddTextButton = 'user-zone-add-text-button',
  UserZoneAddWamButton = 'user-zone-add-wam-button',
  UserZoneInstructions = 'user-zone-add-instructions',
  UserZoneAddStickerButton = 'user-zone-add-sticker-button',
}

export enum CustomFabricType {
  Image = 'photo',
  Sticker = 'sticker',
  Handwriting = 'handwriting',
}

/**
 * Checks in the fabric instance if we already loaded open type with the custom property hasLoadedOpenType
 */
export const hasFabricLoadedOpenType = () => !!(fabric as any).hasLoadedOpenType;

type InitializeCanvasExtensions = {
  fonts: OpenTypeFont[];
  /**
   * This function is called when the text is transformed to an svg path.
   * In order to see it on the canvas you need to add the path to the fabric instance.
   *
   * @example  onTextTransformedToPath(path) => canvas.current.add(path);
   */
  onTextTransformedToPath: (path: fabric.Path) => void;
};
export const initializeCanvasExtensions = ({ fonts, onTextTransformedToPath }: InitializeCanvasExtensions) => {
  const _fabric = fabric as any;
  // custom var we add to the fabric object object when we finish transforming the methods
  if (_fabric.hasLoadedOpenType) {
    return;
  }
  // This is necessary to avoid blurriness on the text
  _fabric.devicePixelRatio = 2;

  /**  Returns the current font family from the canvas font
   *
   * @param font this a string that looks like this '32px fontid-107'
   */
  const getFontFamily = (font: string) => {
    const fontFamily = font.match(/fontid-(\d*)/);
    return fontFamily ? fontFamily[0] : null;
  };

  const getFont = (fontFamily: string | null): opentype.Font | undefined => {
    if (fontFamily === null) {
      return;
    }
    const fontObject = fonts.find((font) => font[`${fontFamily}`]);
    if (!fontObject) {
      return;
    }
    return fontObject[`${fontFamily}`].font;
  };

  const createFontPath = (canvasObject) => {
    const t = canvasObject.transform;
    const fPath = new fabric.Path(canvasObject.path);
    if (!fPath.path) {
      return;
    }
    for (let p = 0; p < fPath.path.length; p += 1) {
      const step = fPath.path[`${p}`] as any;
      for (let i = 1; i < step.length; i += 2) {
        const x = t[0] * step[`${i}`] + t[2] * step[i + 1] + t[4];
        const y = t[1] * step[`${i}`] + t[3] * step[i + 1] + t[5];
        step[`${i}`] = +x.toFixed(2);
        step[i + 1] = +y.toFixed(2);
      }
    }
    const gPath = new fabric.Path(fPath.path);
    gPath.set('fill', canvasObject.fillStyle);

    return gPath;
  };
  /**
   * Returns font size multiplied by 1.
   *
   */
  CanvasRenderingContext2D.prototype.getFontSize = function getFontSize() {
    const font = this.font;
    const fontMatch = font.match(/\d+/);
    if (fontMatch !== null) {
      return 1 * Number(fontMatch[0]);
    }

    return null;
  };

  CanvasRenderingContext2D.prototype.fillText = function fillText(text, x, y) {
    if (!text) {
      return;
    }

    const width = this.measureText(text).width;
    const isRtl = this.direction === 'rtl';
    const offsetFactor = {
      left: 0,
      center: 0.5,
      right: 1,
      start: isRtl ? 1 : 0,
      end: isRtl ? 0 : 1,
    };
    const fontFamily = getFontFamily(this.font);
    const currentFont = fonts.find((fonts) => Object.keys(fonts)[0] === fontFamily);

    if (!currentFont) {
      return;
    }

    const openTypeFont = currentFont[`${fontFamily}`].font;
    const fontSize = this.getFontSize();
    if (!openTypeFont || fontSize === null) {
      return;
    }

    const offsetX = x - width * offsetFactor[this.textAlign];
    const path = openTypeFont.getPath(text, offsetX, y, fontSize);
    path.fill = this.fillStyle as string;
    path.draw(this);

    const m = this.getTransform();

    const r = _fabric.devicePixelRatio || window.devicePixelRatio;
    const fontPath = createFontPath({
      transform: [m.a / r, m.b / r, m.c / r, m.d / r, m.e, m.f],
      path: path.toPathData(2),
      fillStyle: this.fillStyle,
    });

    if (fontPath) {
      onTextTransformedToPath(fontPath);
    }
  };

  CanvasRenderingContext2D.prototype.measureText = function measureText(text) {
    let width = 0;

    const fontFamily = getFontFamily(this.font);
    const font = getFont(fontFamily);
    const fontSize = this.getFontSize();

    if (font && font !== null && fontSize) {
      font.forEachGlyph(`${text} `, 0, 0, fontSize, {}, (_, x) => {
        width = x;
      });
    }

    return {
      width,
    };
  };

  _fabric.hasLoadedOpenType = true;
};

export const getObjectsByType = (canvas: fabric.Canvas, type: CanvasDataTypes) => {
  if (!canvas) {
    throw new Error('Canvas is undefined');
  }

  return canvas.getObjects().filter((obj) => {
    return obj.data?.type === type;
  });
};

export const getGroupedTextObject = (activeCanvas?: fabric.Canvas | null, zone: fabric.Group | null = null) => {
  if (zone) {
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, zone);
    return groupedTextbox as fabric.Textbox;
  } else if (!zone && activeCanvas?.getActiveObject()?.type === 'group') {
    const objectGroup = activeCanvas?.getActiveObject() as fabric.Group;
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, objectGroup);
    return groupedTextbox as fabric.Textbox;
  }

  return activeCanvas?.getActiveObject();
};

export const getGroupedItemByName = (name: CanvasDataTypes, currentGroup: fabric.Group) => {
  return currentGroup._objects.find((item) => item?.name?.includes(name));
};

export const getObjectByName = (name: string, currentCanvas: fabric.Canvas) => {
  return currentCanvas?.getObjects().find((object) => name !== undefined && object.name === name);
};

/**
 * Takes a linkedZoneName and a canvas, and selects the linked zone.
 *
 * @param linkedZoneName - name of linkedZone, usually stored in data.linkedZone
 * @param currentCanvas - current active canvas
 *
 * @example
 *
 *   selectLinkedZone(canvasObject.data.linkedZoneName, currentCanvas);
 */
export const selectLinkedZone = (linkedZoneName: string, currentCanvas: fabric.Canvas) => {
  const linkedZone = getObjectByName(linkedZoneName, currentCanvas);
  if (linkedZone) {
    currentCanvas.setActiveObject(linkedZone);
  }
};

/**
 * Clears photo text zones to original state.
 *
 * @param currentCanvas - current canvas from useCurrentCanvas hook
 * @param cardDispatch - cardDispatch grabbed from cardContext
 * @example
 *
 *   clearPhotoTextZone(canvas.current, cardDispatch);
 */
export const clearPhotoTextZone = (currentCanvas: fabric.Canvas) => {
  // If active object is a temporary text field, select the parent before clearing.
  if (currentCanvas.getActiveObject()?.name === CanvasDataTypes.TemporaryTAMInput) {
    const activeObject = currentCanvas.getActiveObject();
    if (activeObject?.data.linkedZoneName) {
      selectLinkedZone(activeObject.data.linkedZoneName, currentCanvas);
    }
  }

  if (currentCanvas.getActiveObject()?.type === 'group') {
    const activeObject = currentCanvas.getActiveObject();
    const photoTextZoneGroup = activeObject as fabric.Group;
    // If Temporary Input is active, remove it from the canvas
    const temporaryInput = getObjectByName('temporary-input', currentCanvas);
    if (temporaryInput) {
      currentCanvas.remove(temporaryInput);
    }
    // Reset textbox, reset text, and turn invisible
    const textbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZoneGroup) as fabric.Textbox;
    if (textbox) {
      textbox.set({
        ...textbox,
        text: '',
        visible: false,
      });
    }

    const buttonZone = photoTextZoneGroup.item(2);
    buttonZone.setOptions({ visible: true });
    photoTextZoneGroup.data.hasContent = false;
    photoTextZoneGroup.data.warned = false;
    currentCanvas.renderAll();
  }
};

/**
 *
 * @param object fabric object to get the Y position on the card
 * @returns "top" if the provided object is on the top half of the card and "bottom" if it's on the bottom half
 */
export const getObjectPositionY = (object: fabric.Object) => {
  const canvas = object.canvas;
  if (!canvas) {
    return;
  }
  const canvasHalfHeight = canvas.getHeight() / 2;
  const { top = 0 } = object;
  return top < canvasHalfHeight ? 'top' : 'bottom';
};

/**
 * Get phototextzone objects from S&S cards
 * @param canvas fabric canvas
 * @returns array of photoTextZone objects contained in passed canvas
 */
export const getPhotoTextZoneObjects = (canvas: fabric.Canvas, copyObjects = false) => {
  let photoTextZones: fabric.Object[] = [];
  if (canvas) {
    photoTextZones = canvas.getObjects().filter((obj) => isPhotoTextZone(obj));
    if (copyObjects) {
      const zonesCopy: fabric.Object[] = [];
      photoTextZones.forEach((zone) => {
        zonesCopy.push(Object.assign({}, zone));
      });
      return zonesCopy;
    }
  }
  return photoTextZones;
};

/**
 * Get ungrouped text objects that were created when cleaning canvas for /save call
 * @param canvas fabric canvas
 * @returns array of photoTextZone objects contained in passed canvas
 */
export const getUngroupedPhotoTextZoneObjects = (canvas: fabric.Canvas) => {
  let ungroupedText: fabric.Object[] = [];
  if (canvas) {
    ungroupedText = canvas.getObjects().filter((obj) => obj.data?.ungroupedText);
  }
  return ungroupedText;
};

/**
 * Get WAM images added to a phototextzone in S&S cards
 * @param canvas fabric canvas
 * @returns array of WAM image objects from S&S cards
 */
export const getPhotoTextZoneImages = (canvas: fabric.Canvas) => {
  let photoTextZoneImages: fabric.Object[] = [];
  if (canvas) {
    photoTextZoneImages = canvas.getObjects().filter((obj) => obj.data?.isPhotoTextZoneImg);
  }
  return photoTextZoneImages;
};

/**
 * @param canvas fabric canvas
 * @returns array of fold lines from cards
 */
export const getFoldLines = (canvas: fabric.Canvas) => {
  let foldLines: fabric.Object[] = [];
  if (canvas) {
    foldLines = canvas.getObjects().filter((obj) => isFoldLine(obj));
  }
  return foldLines;
};

/**
 *
 * @param object fabric object to check if it is a fold lime
 * @returns true if the provided object is is a fold lime
 */
export const isFoldLine = (object: fabric.Object): object is fabric.Group => {
  return object.data?.type === CanvasDataTypes.FoldLine;
};

/**
 *
 * @param object fabric object to check if it is a photo text zone
 * @returns true if the provided object is a photo text zone
 */
export const isPhotoTextZone = (object: fabric.Object): object is fabric.Group => {
  return !!object.name?.startsWith(CanvasDataTypes.PhotoTextZone);
};

/**
 *
 * @param object fabric object to check if it is a photo zone
 * @returns true if the provided object is a photo zone
 */
export const isPhotoZone = (object: fabric.Object): object is fabric.Object => {
  return object.data?.type === CanvasDataTypes.PhotoZone;
};

/**
 *
 * @param object fabric object to check if it is a editable text
 * @returns true if the provided object is a editable text
 */
export const isEditableText = (object: fabric.Object): object is fabric.Textbox => {
  return object.data?.type === CanvasDataTypes.EditableText;
};

/**
 *
 * @param object fabric object to check if it is a user zone. By user zone we understand any zone that can be edited by users.
 * For instance: Photo Text Zones, Photo Zones and Editable Texts
 * @returns true if the provided object is either a Photo Text Zone, a Photo Zone or an Editable Text
 */
export const isUserZone = (object: fabric.Object) => {
  return isPhotoTextZone(object) || isPhotoZone(object) || isEditableText(object);
};

/**
 *
 * @param photoTextZone The photo text zone from which you will get the description group containing the label and icon
 * @returns the fabric Group inside photo text zone containing icon and label
 */
export const getPhotoTextZoneDescription = (photoTextZone: fabric.Group): fabric.Group | undefined => {
  const groups = photoTextZone.getObjects('group') as fabric.Group[];
  return groups.find((obj) => obj.name === CanvasDataTypes.PhotoTextZoneButton);
};

export const mergeLines = (textbox: fabric.Textbox) => {
  const offset = textbox.selectionEnd || 0;
  textbox.text = textbox.text?.replace(/\n$/, '');
  if (textbox.hiddenTextarea && textbox.text) {
    textbox.hiddenTextarea.value = textbox.text;
  }
  textbox.setSelectionStart(offset - 1);
  textbox.setSelectionEnd(offset);
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const MIN_FONT_SIZE = 16;

export const shrinkText = (textbox: fabric.Textbox, minFontSize: number = MIN_FONT_SIZE) => {
  const currentFontSize = textbox.fontSize as number;
  const newFontSize = currentFontSize - 1 < minFontSize ? minFontSize : currentFontSize - 1;
  textbox.fontSize = newFontSize;
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const expandText = (textbox: fabric.Textbox, maxFontSize: number) => {
  const currentFontSize = textbox.fontSize as number;
  const newFontSize = currentFontSize + 1 > maxFontSize ? maxFontSize : currentFontSize + 1;
  textbox.fontSize = newFontSize;
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const spaceContentEvenly = (group: fabric.Group, gap: number, start = 0) => {
  const items = group._objects;
  items.forEach((item, index) => {
    const previousItem = items[index - 1];
    item.set('left', previousItem ? (previousItem.left as number) + previousItem.getScaledWidth() + gap : start);
  });
};

export const getMaxWidthLine = (textbox: fabric.Textbox) => {
  const linesWidth = textbox.textLines.map((_, index) => textbox.measureLine(index).width);
  return Math.max(...linesWidth);
};

/**
 * rotates an object around its center
 * @param targetObject object to rotate
 * @param rotation angle for rotation
 */
export const setAngle = (targetObject: FabricObject, rotation: number) => {
  (targetObject as CustomFabricObject)._setOriginToCenter();
  (targetObject as CustomFabricObject).set('angle', rotation).setCoords();
  (targetObject as CustomFabricObject)._resetOrigin();
};

/**
 * Calculates the offset between a zone description's label width and measured char width
 * @param zoneDescription zone description containing button and label
 * @returns {number} Returns a photoTextZone's label's offset
 */
export const getZoneLabelOffset = (zoneDescription: fabric.Group): number => {
  let offset = 0;
  const zoneLabel = zoneDescription
    .getObjects()
    .find((obj) => obj.name === CanvasDataTypes.PhotoTextZoneLabel) as fabric.Text;
  if (zoneLabel) {
    const measureDiff = zoneLabel.measureLine(0).width - (zoneLabel.width as number);
    offset = measureDiff > 0 ? measureDiff : 0;
  }
  return offset;
};

/**
 * Hides an object's middle controls
 * @param targetObject target object
 */
export const hideMiddleControls = (targetObject: fabric.Object) => {
  targetObject.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
  });
};

/**
 * add event handlers for customizable texts (placeholder or user-added)
 * @param textElement the text element to add handlers to
 * @param defaultMessage the default message to display on the textbox when it hasn't been edited
 * @param [defaultColor] optional default text color to apply to default message
 *
 * @example
 *
 *  addUserTextHandlers(textElement, INITIAL_TEXT_MESSAGE, BrandColors.purple)
 */
export const addUserTextHandlers = (
  textbox: fabric.Textbox,
  initialText: string,
  color: string,
  canvas: fabric.Canvas,
) => {
  const adjustTextForHeight = (tb: fabric.Textbox) => {
    if (!tb.height) return false;
    const maxHeight = canvas.getHeight() * 0.8;
    if (tb.height <= maxHeight) return false;
    const currentFontSize = tb.fontSize as number;
    const ratio = maxHeight / tb.height;
    const newFontSize = Math.max(Math.floor(currentFontSize * ratio), MIN_FONT_SIZE);

    if (newFontSize === currentFontSize) return false;

    tb.set({
      fontSize: newFontSize,
      height: maxHeight,
    });
    return true;
  };

  const debouncedAdjustHeight = debounce(adjustTextForHeight, 100, {
    leading: true,
    trailing: true,
  });

  textbox.on('editing:entered', function (this: fabric.Textbox & { _textBeforeEdit: string }) {
    if (this._textBeforeEdit === initialText) {
      const currentFill = this.fill;
      const currentFillMatch = Object.keys(colorsList).find(
        (key) => colorsList[`${key}`].toUpperCase() === currentFill?.toString().toUpperCase(),
      );
      const isDefaultColor = currentFill === color;
      const colorName = isDefaultColor ? CEShadeColors.DarkBlack : currentFillMatch;

      this.set({
        text: '',
        fill: colorsList[`${colorName}`],
        data: { ...this.data, edited: true },
      });

      if (this.hiddenTextarea) {
        this.hiddenTextarea.value = '';
      }
    }
  });

  textbox.on('changed', () => {
    debouncedAdjustHeight(textbox);
  });

  textbox.on('editing:exited', function (this: fabric.Textbox) {
    if (this.text === '') {
      this.set({
        text: initialText,
        data: { ...this.data, isEdited: false, edited: false },
      });
    }
    if (textbox.canvas) {
      textbox.canvas.renderAll();
    }
  });

  textbox.on('mousedblclick', function (this: fabric.Textbox & { _textBeforeEdit: string; __lastIsEditing: boolean }) {
    if (isAndroid) {
      this.enterEditing();
      if (this._textBeforeEdit !== initialText || this.__lastIsEditing) {
        this.selectAll();
      }

      setTimeout(() => {
        if (this.hiddenTextarea) {
          this.hiddenTextarea.focus();
          this.hiddenTextarea.click();
          this.hiddenTextarea.setAttribute('inputmode', 'text');
          this.hiddenTextarea.setAttribute('type', 'text');

          const touch = new TouchEvent('touchstart', {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          this.hiddenTextarea.dispatchEvent(touch);
        }
      }, 100);
    } else {
      this.enterEditing();
      if (this._textBeforeEdit !== initialText || this.__lastIsEditing) {
        this.selectAll();
      }
    }
  });
};

/**
 * gets a clipPath if present on current canvas so that images and text can be added within
 * @param currentFace current cardface from cardFacesList, not the cardState.activeCanvas
 * @param areaIndex index of the activeArea in the array of editableAreas
 *
 * @example
 *
 *  getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
 */
export const getCardFaceClipPath = (currentFace: any, areaIndex: number) => {
  if (!currentFace.clipPaths) return;
  return currentFace.clipPaths[`${areaIndex}`];
};

export const photoFrameName = 'photo-frame';

/**
 *
 * @param x will be image width
 * @param y will be image height
 * @param zw will be photozone width
 * @param zh will be photozone height
 * @returns scale ratio
 */
export const setPhotoZoneScaleRatio = (x: number, y: number, zw: number, zh: number) => {
  const ratio = Math.min(zh / y, zw / x);
  return ratio;
};
/**
 * Fills a given photo-zone using the id. This will update the canvas with the given image
 * @param photoZoneId
 * @param image
 * @param faces
 * @param type
 */
export function fillPhotoZone(
  photoZoneId: string,
  image: fabric.Image,
  faces: CardFaceData[],
  type: FillPhotoZoneType,
) {
  faces.forEach((face: CardFaceData) => {
    const canvas = face.canvas.current;
    //find the current PhotoZone
    const canvasObjects = canvas?.getObjects() ?? [];
    const currentPhotoZone = canvasObjects.find((object) => object.name === photoZoneId);

    if (currentPhotoZone) {
      //fills the PhotoZone
      const clipPath = new fabric.Rect({
        width: currentPhotoZone.width,
        height: currentPhotoZone.height,
        left: currentPhotoZone.left,
        angle: currentPhotoZone.angle,
        top: currentPhotoZone.top,
        absolutePositioned: true,
      });
      // Calculate the scale ratio
      const scaleRatio = setPhotoZoneScaleRatio(
        image.width as number,
        image.height as number,
        currentPhotoZone.width as number,
        currentPhotoZone.height as number,
      );

      // Calculate the center position
      const centerX = (currentPhotoZone.left as number) + (currentPhotoZone.width as number) / 2;
      const centerY = (currentPhotoZone.top as number) + (currentPhotoZone.height as number) / 2;

      image.set({
        left: centerX - ((image.width as number) * scaleRatio) / 2,
        top: centerY - ((image.height as number) * scaleRatio) / 2,
        clipPath,
        data: {
          ...image.data,
          photoZoneId: photoZoneId,
          type: CanvasDataTypes.PhotoZoneImage,
        },
        angle: currentPhotoZone.angle,
        scaleX: scaleRatio,
        scaleY: scaleRatio,
        onSelect: () => {
          image.set({
            data: {
              ...image.data,
            },
          });
          return false;
        },
      });
      config?.customControls?.image?.hideMiddleControls && hideMiddleControls(image);

      if (type === FillPhotoZoneType.CROPPING) {
        canvas?.add(image);
      }
      image.sendToBack();
      currentPhotoZone.set({ visible: false });
      currentPhotoZone.data.hasContent = true;

      // Orders the canvas
      canvasObjects.forEach((object) => {
        // The photo zone button
        if (object.name === `${photoZoneId}-button`) {
          object.set({ visible: false });
        }
        if (object.name === photoFrameName) {
          object.bringToFront();
        }
      });
      canvas?.setActiveObject(image);
    }
  });
}

/**
 *   Determines if there are available photo zones in the current canvas.
 * @param canvas
 * @returns true if all the photo zones are filled.
 */
export function photoZonesAreFilled(canvas: fabric.Canvas) {
  const photoZones = getObjectsByType(canvas, CanvasDataTypes.PhotoZone);

  if (photoZones.length === 0) {
    return false;
  }

  const slots = photoZones.filter((zone) => !zone.data?.hasContent);
  return slots.length === 0;
}
/** Determines if are photo zones awaitables in the canvas to be filled  */
export function hasAvailablePhotoZones(canvas: fabric.Canvas) {
  const photoZones = getObjectsByType(canvas, CanvasDataTypes.PhotoZone);
  const slots = photoZones.filter((zone) => !zone.data?.hasContent);
  return slots.length > 0;
}
/** Loads an edit icon for the editable texts
 */
export function getEditableTextButton(settings: EditableTextSettings, scale: number): Promise<fabric.Image> {
  return new Promise((resolve) => {
    // edit button
    fabric.Image.fromURL(
      config.customControls?.icons?.editIcon || editTextIcon,
      (image) => {
        image.scaleToWidth(scale);

        image.setOptions({
          name: 'edit-icon',
          hoverCursor: 'pointer',
          originY: settings.originY,
          left: settings.left + settings.width / 2,
          top: settings.top,
          angle: settings.angle,
          data: {
            type: CanvasDataTypes.EditableTextButton,
          },
        });

        resolve(image);
      },
      { width: 48, height: 48 },
    );
  });
}

export function setEditableTextHandlers(
  image: fabric.Image,
  editableInput: FabricTextBox,
  maxFontSize: number,
  maxLines: number,
  cardState: CardContextState,
) {
  const isAndroid = /Android/i.test(navigator.userAgent);
  let isToastPresented = false;

  image.set({ selectable: false, evented: false });

  editableInput.set({
    selectable: true,
    evented: true,
  });

  editableInput.onDeselect = () => {
    if (editableInput.text === '') {
      image.visible = true;
    }

    editableInput.exitEditing();
    editableInput.set({
      selectable: true,
      evented: true,
    });
    return false;
  };

  const handleTextboxClick = (shouldEnterEditing: boolean) => {
    image.visible = false;

    const templateTextField = findTextById(cardState.cardFacesList, editableInput.data.ID);

    editableInput.set({
      data: {
        ...editableInput.data,
        originalText: templateTextField?.Text,
        isEdited: true,
        hasContent: true,
        firstInputTracked: false,
        type: CanvasDataTypes.EditableText,
        maxFontSize,
        fixedWidth: editableInput.getScaledWidth(),
        maxLines,
      },
      lockMovementX: templateTextField ? templateTextField.IsFixed : true,
      lockMovementY: templateTextField ? templateTextField.IsFixed : true,
      CanResizeTextArea: templateTextField ? templateTextField.CanResizeTextArea : true,
      CanEditFontSize: templateTextField ? templateTextField.CanEditFontSize : true,
      FontAutoSize: templateTextField ? templateTextField.FontAutoSize : false,
      CanEditFontColor: templateTextField ? templateTextField.CanEditFontColor : false,
      selectable: true,
      evented: true,
    });

    if (shouldEnterEditing) {
      editableInput.enterEditing();

      setTimeout(() => {
        if (editableInput.hiddenTextarea) {
          editableInput.hiddenTextarea.focus();
          editableInput.hiddenTextarea.click();

          if (isAndroid && !isToastPresented) {
            editableInput.hiddenTextarea.setAttribute('inputmode', 'text');
            editableInput.hiddenTextarea.setAttribute('type', 'text');

            const customEvent = new CustomEvent('textboxClickAndroid', {
              detail: {
                textbox: editableInput,
              },
            });
            document.dispatchEvent(customEvent);
            isToastPresented = true;
          }
        }
      }, 100);
    }
  };

  editableInput.on('mousedown', () => {
    if (isAndroid) {
      trackTextboxClicks(editableInput, (isDoubleClick) => {
        handleTextboxClick(isDoubleClick);
      });
    } else {
      handleTextboxClick(false);
    }
  });

  const triggeredElements = new Set<string>();

  editableInput.on('changed', function (this: fabric.Textbox) {
    const elementId = this.data?.ID;

    if (elementId && !triggeredElements.has(elementId) && this.text !== this.data?.originalText && this.isEditing) {
      triggeredElements.add(elementId);

      const textAddedEventData: Omit<PodTextAdded, 'event_id'> = {
        event: 'pod_text_added',
        page_number: '1',
      };
      pushPodTextAdded(textAddedEventData);
    }
  });
}
/**
 * Filters and retrieves text boxes and images from the canvas.
 * @param canvas - The fabric.js canvas instance.
 * @returns An array of objects and their corresponding indices.
 */
export const getTextAndImageObjects = (canvas: fabric.Canvas): { obj: fabric.Object; index: number }[] => {
  return canvas
    .getObjects()
    .reduce((acc: { obj: fabric.Object; index: number }[], obj: fabric.Object, index: number) => {
      if (obj.type === 'image' || obj.type === 'textbox') {
        acc.push({ obj, index });
      }
      return acc;
    }, []);
};

/**
 * Moves a target object to a new order within the filtered list of text boxes and images.
 * @param canvas - The fabric.js canvas instance.
 * @param targetObject - The target object to be moved.
 * @param newOrderIndex - The new order index to move the target object to.
 */
export const moveObjectWithinFiltered = (
  canvas: fabric.Canvas,
  targetObject: fabric.Object,
  newOrderIndex: number,
): void => {
  const filteredObjects = getTextAndImageObjects(canvas);
  const currentIndex = filteredObjects.findIndex((item) => item.obj === targetObject);

  if (currentIndex === -1 || newOrderIndex < 0 || newOrderIndex >= filteredObjects.length) {
    // eslint-disable-next-line no-console
    console.error('Invalid order operation');
    return;
  }

  // Remove target object from its current position
  const [removedObject] = filteredObjects.splice(currentIndex, 1);
  // Insert it into the new position
  filteredObjects.splice(newOrderIndex, 0, removedObject);

  // Move objects within canvas based on their new order in filteredObjects
  filteredObjects.forEach(({ obj }, idx) => {
    obj.moveTo(idx + 1); // +1 to account for the default canvas at index 0
  });

  canvas.renderAll();
};
