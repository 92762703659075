import { useCallback, useState } from 'react';
import { useAppContext } from '../../../context/app-context';
import { showLoadingScreen, hideLoadingScreen } from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { setIsPreviewDialogOpen } from '../../../context/card-context/card-context-actions';
import { CardType, ErrorResponse } from '../../../global-types';
import { useBasket, useSaveProject, useSystemErrorHandling } from '../../../hooks';
import { useEmailOrderProcess } from '../../../hooks/useEmailOrderProcess';
import { useSubmitOrder } from '../../../hooks/useSubmitOrder';
import { usePreviewGenerator } from './usePreviewGenerator';

export const useOrchestrateActions = () => {
  const [isOrchestrating, setIsOrchestrating] = useState(false);
  const { appDispatch } = useAppContext();
  const { cardState, cardDispatch } = useCardContext();
  const { saveProject } = useSaveProject();
  const { loadPreview, fetchDigitalAssets } = usePreviewGenerator();
  const { sendEmailNow } = useEmailOrderProcess();
  const { addToCart } = useBasket();

  const [onSystemError] = useSystemErrorHandling();

  const updateIsPreviewDialogOpen = (isOpen: boolean) => setIsPreviewDialogOpen(cardDispatch, isOpen);

  const { handleSubmit } = useSubmitOrder(updateIsPreviewDialogOpen, addToCart);

  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;

  const orchestrateActions = useCallback(async () => {
    if (isOrchestrating) return;

    setIsOrchestrating(true);
    showLoadingScreen(appDispatch, '');
    let redirecting = false;

    try {
      // Step 1: Save the project
      const saveData = await saveProject({
        shouldRestoreCanvas: true,
        isSaveButtonClicked: false,
        generatePreviews: true,
        isDraftSave: false,
      });

      if (!saveData) {
        throw new Error('Failed to save project, data was undefined.');
      }

      // Step 2: Load preview which could include generating previews
      const previewImages = await loadPreview(saveData);

      // Step 3: Fetch digital assets if applicable
      // Check if the conditions are met to fetch digital assets
      if (isDigitalGreetings && previewImages.length >= 2 && previewImages[0].url && previewImages[1].url) {
        const assetData = await fetchDigitalAssets(previewImages[0].url, previewImages[1].url);

        // Now use the returned URL to send the email if isDigitalGreetings
        // Step 4: Sending email
        if (assetData && assetData.url) {
          await sendEmailNow(assetData.url);
        }
      }
      // Step 5: Handle order submission - constructAddToCartURL and addProjectToCart for finish if preview images are available
      if (previewImages.length > 0) {
        redirecting = true;
        setTimeout(() => {
          handleSubmit(previewImages);
        }, 100);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during process execution:', error);
      onSystemError(error as ErrorResponse);
      hideLoadingScreen(appDispatch);
      throw error;
    } finally {
      if (!redirecting) {
        setIsOrchestrating(false);
        hideLoadingScreen(appDispatch);
      }
    }
  }, [
    showLoadingScreen,
    hideLoadingScreen,
    saveProject,
    loadPreview,
    fetchDigitalAssets,
    sendEmailNow,
    handleSubmit,
    appDispatch,
    cardState,
  ]);

  return {
    orchestrateActions,
    isOrchestrating,
  };
};
