import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../context/app-context';
import { setActiveCardIndex, useCardContext } from '../context/card-context';
import { setSlideCardIndex } from '../context/card-context/card-context-actions';
import { isLoggedIn } from '../utils';
import { PodInsideCardLoaded, PodMainPageLoaded } from '../utils/analytics/analytics-types';
import { pushPodInsideCardLoaded, pushPodMainPageLoaded } from '../utils/analytics/analytics-utils';
import { OnCardFaceIndexChangeOptions, useOnCardFaceIndexChange } from '../views/editor/hooks/useOnCardFaceIndexChange';
import { useActiveCanvas } from './useActiveCanvas';

export const useHandleIndexChange = ({ savingCard }: { savingCard?: boolean }) => {
  const [beforeEditingFaceJson, setBeforeEditingFaceJson] = useState<string | null>(null);
  const activeCanvas = useActiveCanvas();
  const { cardState, cardDispatch } = useCardContext();
  const { onCardFaceIndexChange } = useOnCardFaceIndexChange();
  const {
    appState: { cardFacesLoaded },
  } = useAppContext();

  const slideIndex = cardState.slideCardIndex;

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );

  const handleIndexChange = useCallback(
    (newIndex: number, newActiveCardIndex?: number) => {
      activeCanvas?.current?.discardActiveObject();

      const indexChangeOptions: OnCardFaceIndexChangeOptions = {
        activeCanvas: activeCanvas,
        editableFaces: cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
        slideIndex,
        beforeEditingFaceJson,
        isLoggedIn: isLoggedIn(),
      };

      if (savingCard) onCardFaceIndexChange(indexChangeOptions);
      setActiveCardIndex(cardDispatch, newActiveCardIndex ?? newIndex);
      setSlideCardIndex(cardDispatch, newIndex);

      const targetIndex = newActiveCardIndex ?? newIndex;
      if (targetIndex === 1) {
        const insideCardLoadedEventData: Omit<PodInsideCardLoaded, 'event_id'> = {
          event: 'pod_inside_card_loaded',
        };
        pushPodInsideCardLoaded(insideCardLoadedEventData);
      } else if (targetIndex === 0 && cardFacesLoaded && cardState.cardFacesList.length > 0) {
        setTimeout(() => {
          const mainPageEventData: Omit<PodMainPageLoaded, 'event_id'> = {
            event: 'pod_main_page_loaded',
            front_editable: cardState?.cardFacesList[0]?.isEditable ?? false,
            inside_editable: cardState?.cardFacesList[1]?.isEditable ?? false,
            item_id: cardState?.projectId || '',
            item_name: cardState?.cardTemplateName || '',
            card_template_id: cardState?.cardTemplateId,
          };
          pushPodMainPageLoaded(mainPageEventData);
        }, 0);
      }
    },
    [
      cardDispatch,
      onCardFaceIndexChange,
      cardState.cardFacesList,
      cardState.projectId,
      cardState.cardTemplateName,
      cardState.cardTemplateId,
      cardFacesLoaded,
    ],
  );

  useEffect(() => {
    // If cardFaces haven't loaded yet or is not saving activated, exit the code
    if (editableFaces.length <= 0 || !savingCard) {
      return;
    }

    // Check if frontFace canvas has loaded
    const isFrontFaceLoaded = editableFaces[0]?.canvas !== undefined;

    if (!beforeEditingFaceJson && isFrontFaceLoaded && cardFacesLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[0].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
    if (beforeEditingFaceJson && isFrontFaceLoaded) {
      const editableObjectsJson = JSON.stringify(editableFaces[`${slideIndex}`].canvas.current?.toJSON().objects);
      setBeforeEditingFaceJson(editableObjectsJson);
    }
  }, [slideIndex, editableFaces, cardFacesLoaded]);

  return handleIndexChange;
};
